.react-parallax-nopadding {
  margin-right: -15px;
  margin-left: -15px;
}
.footer {
  font-size: 0.7rem;
}

.mt-n-1 {
  @include media-breakpoint-down(xs) {
    margin-top: -70px;
  }
}
