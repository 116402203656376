//Poulp color scheme
$gray-100: #f2f2f2;
$body-bg: gray("200");
$body-color: gray("900");

$primary: #464646;
$secondary: #b2aeae;
$third: #ff303d;
$fourth: #f5f5f5;

$display1-size: 4rem;
$display2-size: 3.5rem;
$display3-size: 2rem;
$display4-size: 1.5rem;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "third": $third,
  "fourth": $fourth
);

@import url("https://use.typekit.net/fws4dcy.css");
$font-family-sans-serif: niveau-grotesk, sans-serif, -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
$font-weight-lighter: 100;
$font-weight-light: 200;
$font-weight-normal: 300;
$font-weight-bold: 600;
$font-weight-bolder: 900;

$btn-border-radius: 50rem;
$btn-border-radius-lg: $btn-border-radius;
$input-border-radius: $btn-border-radius;
$input-border-radius-lg: $btn-border-radius;

$modal-backdrop-bg: white;
$modal-backdrop-opacity: 0.7;
$modal-header-border-color: $primary;
$modal-content-border-color: $secondary;

/* Color Theme Swatches in Hex 
$Social-media-or-social-network-notification-icons-with-abstract-blurred-event-exhibition-or-business-convention-show,-Big-data-and-digital-networking-concept-1-hex: #db5d7c;
$Social-media-or-social-network-notification-icons-with-abstract-blurred-event-exhibition-or-business-convention-show,-Big-data-and-digital-networking-concept-2-hex: #243659;
$Social-media-or-social-network-notification-icons-with-abstract-blurred-event-exhibition-or-business-convention-show,-Big-data-and-digital-networking-concept-3-hex: #5392c9;
$Social-media-or-social-network-notification-icons-with-abstract-blurred-event-exhibition-or-business-convention-show,-Big-data-and-digital-networking-concept-4-hex: #00d6b2;
$Social-media-or-social-network-notification-icons-with-abstract-blurred-event-exhibition-or-business-convention-show,-Big-data-and-digital-networking-concept-5-hex: #f2f2f2;
*/
